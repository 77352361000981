import * as React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { SpinButton, Toggle, Stack, getTheme, TextField, Dropdown, Text, TooltipHost, DirectionalHint, Icon, IDropdownOption, PrimaryButton, DefaultButton, StackItem } from '@fluentui/react';
import { DiagnosticCases, IDiagnosticsPiceaCaseConfig } from '@piceasoft/core';
import { IDiagnosticsPiceaCaseOptions } from '@piceasoft/core';
import { strings } from '../../../../../../../../../localization/strings';
import { onRenderLabel } from '../../../../../../../../renders/onRenderLabel';
import { Tooltip } from '../../../../../../../help/Tooltip';
import { DeviceCheckSolutions } from '../../../../../../../../../core/store/typings/DeviceCheckSolutions';


type TProps = {
    onConfirmCase: (caseItem: IDiagnosticsPiceaCaseConfig) => void
    caseElement?: IDiagnosticsPiceaCaseConfig
    options?: IDiagnosticsPiceaCaseOptions
    handleCancelClick: () => void
    diagnosticType?: number
}

export const DiagnosticCaseOptionsForm: React.FC<TProps> = ({ caseElement, options, onConfirmCase, handleCancelClick, diagnosticType}) => {
    const { control, handleSubmit, reset, formState: { errors } } = useForm<IDiagnosticsPiceaCaseOptions>({ defaultValues: { ...options } });

    const onSubmit: SubmitHandler<IDiagnosticsPiceaCaseOptions> = data => {
        if (caseElement) {
            caseElement.options = { ...data }
            onConfirmCase({
                ...caseElement
            })
            handleCancelClick()
        }
        handleCancelClick()
    };

    const [errorMessageText, setErrorMessageText] = React.useState('');

    const phoneNumberRegex = new RegExp(/^(\+[0-9]{1,3}){0,1}\s{0,1}[0-9]{4,14}$/);
    React.useEffect(() => {
        reset(options, { keepValues: false })
    }, [options])

    const cpuLoadOptionsBatteryTest: IDropdownOption[] = [
        { key: 'low', text: `${strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_BATTERY_STRESS.CPU_LOAD_LEVEL_LOW}` },
        { key: 'high', text: `${strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_BATTERY_STRESS.CPU_LOAD_LEVEL_HIGH}` }
    ]
    const securityOptionsWiFiTest: IDropdownOption[] = [
        { key: 0, text: `${strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_SOFTWARE_UPDATES.SECURITY_OPEN}` },
        { key: 1, text: `${strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_SOFTWARE_UPDATES.SECURITY_WEP}` },
        { key: 2, text: `${strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_SOFTWARE_UPDATES.SECURITY_WPA}` }
    ]
    const touchPatternTypeOptionsDisplayTouchTest: IDropdownOption[] = [
        { key: 1, text: `${strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_DISPLAY_TOUCH.TOUCH_PATTERN_TYPE_ONE}` },
        { key: 2, text: `${strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_DISPLAY_TOUCH.TOUCH_PATTERN_TYPE_TWO}` },
        { key: 3, text: `${strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_DISPLAY_TOUCH.TOUCH_PATTERN_TYPE_THREE}` },
        { key: 4, text: `${strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_DISPLAY_TOUCH.TOUCH_PATTERN_TYPE_FOUR}` },
        { key: 5, text: `${strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_DISPLAY_TOUCH.TOUCH_PATTERN_TYPE_FIVE}` },
        { key: 6, text: `${strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_DISPLAY_TOUCH.TOUCH_PATTERN_TYPE_SIX}` },
    ]

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
            <Stack verticalFill style={{ padding: '0 20px' }}>
                <Stack.Item verticalFill style={{ position: 'relative' }}>
                    <Stack>
                        <Stack.Item>
                        <Controller control={control}
                                name="stop_on_failure"
                                render={({ field }) =>
                                    <Stack tokens={{ childrenGap: 6 }}>
                                        <Stack.Item>
                                            <Stack horizontal tokens={{ childrenGap: 6 }}>
                                                <Stack.Item>
                                                    <Text style={{ color: theme.palette.black, fontWeight: 500 }}>{strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.COMMON.STOP_ON_FAILURE}</Text>
                                                </Stack.Item>
                                                <Stack.Item align='end'>
                                                    <Tooltip content={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.COMMON.STOP_ON_FAILURE_INFO}>
                                                        <Icon iconName="Info" styles={{ root: { display: "flex" } }} />
                                                    </Tooltip>
                                                </Stack.Item>
                                            </Stack>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Toggle
                                                id={"stop_on_failure"}
                                                inlineLabel
                                                checked={(field.value) ? true : false}
                                                onChange={(ev, checked) => {
                                                    field.onChange(checked)
                                                }}
                                                onText={strings.BUTTONS.TOGGLE_ENABLE}
                                                offText={strings.BUTTONS.TOGGLE_DISABLE}
                                            />
                                        </Stack.Item>
                                    </Stack>
                                }
                            />
                        </Stack.Item>
                        {(caseElement?.id === DiagnosticCases.TC_STORAGE || caseElement?.id === DiagnosticCases.TC_RAM)&&(diagnosticType !== undefined && diagnosticType === DeviceCheckSolutions.PiceaMobile ) &&
                        <StackItem>
                            <Controller control={control}
                                        name="limit"
                                        render={({ field }) =>
                                        <Stack tokens={{ childrenGap: 6 }}>
                                            <Stack.Item>
                                            <Stack horizontal tokens={{ childrenGap: 6 }}>
                                            <Stack.Item>
                                                <Text style={{ color: theme.palette.black, fontWeight: 500 }}>{strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.LIMIT_LABEL}</Text>
                                            </Stack.Item>
                                            <Stack.Item align='end'>
                                                    <Tooltip content={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.LIMIT_LABEL}>
                                                        <Icon iconName="Info" styles={{ root: { display: "flex" } }} />
                                                    </Tooltip>
                                                </Stack.Item>
                                                </Stack>
                                            </Stack.Item>    
                                            <SpinButton {...field}
                                                defaultValue='80'
                                                value={field.value !== undefined && String(field.value) !== '' ? String(field.value): '80'}
                                                title={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.LIMIT_NAME}
                                                placeholder={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.LIMIT_NAME_PLACEHOLDER}
                                                min={0}
                                                max={100}
                                                step={1}
                                                onChange={(e, v) => {
                                                    field.onChange(v)
                                                }}
                                            />
                                        </Stack>    
                                        }
                            />
                        </StackItem>}
                        {caseElement?.id === DiagnosticCases.TC_DISPLAY_TOUCH && (
                            <Stack.Item>
                                <Stack tokens={{ childrenGap: 10 }}>
                                    <Controller control={control}
                                        name="touch_pattern_type"
                                        render={({ field }) =>
                                            <Dropdown {...field}
                                                selectedKey={field.value}
                                                onChange={(ev, opt) => field.onChange(opt?.key)}
                                                options={touchPatternTypeOptionsDisplayTouchTest}
                                                onRenderLabel={onRenderLabel}
                                                label={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_DISPLAY_TOUCH.TOUCH_PATTERN_TYPE}
                                                title={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_DISPLAY_TOUCH.TOUCH_PATTERN_TYPE_INFO}
                                                placeholder={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_DISPLAY_TOUCH.TOUCH_PATTERN_TYPE_PLACEHOLDER}
                                            />} />
                                </Stack>
                            </Stack.Item>
                        )}
                        {caseElement?.id === DiagnosticCases.TC_CALL && (
                            <Stack.Item>
                                <Stack tokens={{ childrenGap: 10 }} >
                                    <Controller control={control}
                                        name="phone_number"
                                        render={({ field }) =>
                                            <TextField {...field}
                                                onRenderLabel={onRenderLabel}
                                                value={field.value}
                                                label={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_CALL.PHONE_NUMBER}
                                                title={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_CALL.PHONE_NUMBER_INFO}
                                                placeholder={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_CALL.PHONE_NUMBER_PLACEHOLDER}
                                                onChange={(e, v) => {  
                                                    if(v) {
                                                        setErrorMessageText(
                                                            phoneNumberRegex.test(v) ? "" : strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PHONE_FILL
                                                        )
                                                    } else {
                                                        setErrorMessageText("")
                                                    }
                                                    field.onChange(v)
                                                }}
                                                errorMessage={errorMessageText}
                                            />} />
                                    <Controller control={control}
                                        name="is_manual"
                                        render={({ field }) =>
                                            <Stack tokens={{ childrenGap: 6 }}>
                                                <Stack.Item>
                                                    <Stack horizontal tokens={{ childrenGap: 6 }}>
                                                        <Stack.Item>
                                                            <Text style={{ color: theme.palette.black, fontWeight: 500 }}>{strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_CALL.IS_MANUAL}</Text>
                                                        </Stack.Item>
                                                        <Stack.Item align='end'>
                                                            <TooltipHost directionalHint={DirectionalHint.bottomCenter}
                                                                content={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_CALL.IS_MANUAL_INFO}
                                                                styles={{ root: { display: "flex", cursor: "default" } }}
                                                                tooltipProps={{
                                                                    calloutProps: {
                                                                        styles: {
                                                                            beak: { background: theme.palette.black },
                                                                            beakCurtain: { background: theme.palette.black, color: "red !important", fontWeight: 600 },
                                                                            calloutMain: { background: theme.palette.black, "p": { color: theme.palette.white } }
                                                                        },
                                                                    },
                                                                }}>
                                                                <Icon iconName="Info" styles={{ root: { display: "flex" } }} /></TooltipHost>
                                                        </Stack.Item>
                                                    </Stack>
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <Toggle
                                                        inlineLabel
                                                        checked={field.value}
                                                        onChange={(ev, checked) => field.onChange(checked)}
                                                        onText={strings.BUTTONS.TOGGLE_ENABLE}
                                                        offText={strings.BUTTONS.TOGGLE_DISABLE} />
                                                </Stack.Item>
                                            </Stack>} />
                                </Stack>
                            </Stack.Item>
                        )}
                        {(caseElement?.id === DiagnosticCases.TC_SOFTWARE_UPDATES || caseElement?.id === DiagnosticCases.TC_WIFI) && (
                            <Stack.Item>
                                <Stack tokens={{ childrenGap: 10 }}>
                                    <Controller control={control}
                                        name="ssid"
                                        render={({ field }) =>
                                            <TextField {...field}
                                                onRenderLabel={onRenderLabel}
                                                label={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_SOFTWARE_UPDATES.SSID}
                                                title={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_SOFTWARE_UPDATES.SSID_INFO}
                                                placeholder={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_SOFTWARE_UPDATES.SSID_PLACEHOLDER}
                                            />} />
                                    <Controller control={control}
                                        name="password"
                                        render={({ field }) =>
                                            <TextField {...field}
                                                onRenderLabel={onRenderLabel}
                                                label={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_SOFTWARE_UPDATES.PASSWORD}
                                                title={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_SOFTWARE_UPDATES.PASSWORD_INFO}
                                                placeholder={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_SOFTWARE_UPDATES.PASSWORD_PLACEHOLDER}
                                            />} />
                                    <Controller control={control}
                                        name="security"
                                        render={({ field }) =>
                                            <Dropdown {...field}
                                                selectedKey={field.value}
                                                onChange={(ev, opt) => field.onChange(opt?.key)}
                                                options={securityOptionsWiFiTest}
                                                onRenderLabel={onRenderLabel}
                                                label={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_SOFTWARE_UPDATES.SECURITY}
                                                title={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_SOFTWARE_UPDATES.SECURITY_INFO}
                                                placeholder={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_SOFTWARE_UPDATES.SECURITY_PLACEHOLDER}
                                            />} />
                                    <Controller control={control}
                                        name="hidden"
                                        render={({ field }) =>
                                            <Stack tokens={{ childrenGap: 6 }}>
                                                <Stack.Item>
                                                    <Stack horizontal tokens={{ childrenGap: 6 }}>
                                                        <Stack.Item>
                                                            <Text style={{ color: theme.palette.black, fontWeight: 500 }}>{strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_SOFTWARE_UPDATES.HIDDEN}</Text>
                                                        </Stack.Item>
                                                        <Stack.Item align='end'>
                                                            <TooltipHost directionalHint={DirectionalHint.bottomCenter}
                                                                content={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_SOFTWARE_UPDATES.HIDDEN_INFO}
                                                                styles={{ root: { display: "flex", cursor: "default" } }}
                                                                tooltipProps={{
                                                                    calloutProps: {
                                                                        styles: {
                                                                            beak: { background: theme.palette.black },
                                                                            beakCurtain: { background: theme.palette.black, color: "red !important", fontWeight: 600 },
                                                                            calloutMain: { background: theme.palette.black, "p": { color: theme.palette.white } }
                                                                        },
                                                                    },
                                                                }}>
                                                                <Icon iconName="Info" styles={{ root: { display: "flex" } }} /></TooltipHost>
                                                        </Stack.Item>
                                                    </Stack>
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <Toggle
                                                        inlineLabel
                                                        id={'hidden'}
                                                        checked={field.value}
                                                        onChange={(ev, checked) => field.onChange(checked)}
                                                        onText={strings.BUTTONS.TOGGLE_ENABLE}
                                                        offText={strings.BUTTONS.TOGGLE_DISABLE} />
                                                </Stack.Item>
                                            </Stack>} />
                                </Stack>
                            </Stack.Item>
                        )}
                        {(caseElement?.id === DiagnosticCases.TC_BATTERY || caseElement?.id === DiagnosticCases.TC_BATTERY_STRESS) && (
                            <Controller control={control}
                            name="failure_level"
                            render={({ field }) =>
                                <Stack tokens={{ childrenGap: 8 }}>
                                    <Stack horizontal tokens={{ childrenGap: 6 }}>
                                        <Stack.Item>
                                            <Text style={{ color: theme.palette.black, fontWeight: 500 }}>{strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_BATTERY_STRESS.FAILURE_LEVEL}</Text>
                                        </Stack.Item>
                                        <Stack.Item align='end'>
                                            <TooltipHost directionalHint={DirectionalHint.bottomCenter}
                                                content={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_BATTERY_STRESS.FAILURE_LEVEL_INFO}
                                                styles={{ root: { display: "flex", cursor: "default" } }}
                                                tooltipProps={{
                                                    calloutProps: {
                                                        styles: {
                                                            beak: { background: theme.palette.black },
                                                            beakCurtain: { background: theme.palette.black, color: "red !important", fontWeight: 600 },
                                                            calloutMain: { background: theme.palette.black, "p": { color: theme.palette.white } }
                                                        },
                                                    },
                                                }}>
                                                <Icon iconName="Info" styles={{ root: { display: "flex" } }} /></TooltipHost>
                                        </Stack.Item>
                                    </Stack>
                                    <SpinButton
                                        id={"failure_level"}
                                        value={field.value?.toString()}
                                        min={16}
                                        max={100}
                                        step={1}
                                        onChange={(ev, opt) => field.onChange(Number(opt))}
                                        incrementButtonAriaLabel="Increase value by 1"
                                        decrementButtonAriaLabel="Decrease value by 1"
                                    /></Stack>} />
                        )}
                        {caseElement?.id === DiagnosticCases.TC_UPTIME && (
                            <StackItem>
                                <Controller control={control}
                                    name="uptime_warning_limit"
                                    render={({ field }) =>
                                        <Stack tokens={{ childrenGap: 6 }}>
                                            <Stack.Item>
                                                <Stack horizontal tokens={{ childrenGap: 6 }}>
                                                    <Stack.Item>
                                                        <Text style={{ color: theme.palette.black, fontWeight: 500 }}>{strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.UPTIME_WARNING_LIMIT_LABEL_HOURS}</Text>
                                                    </Stack.Item>
                                                    <Stack.Item align='end'>
                                                        <Tooltip content={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.UPTIME_WARNING_LIMIT_LABEL_HOURS}>
                                                            <Icon iconName="Info" styles={{ root: { display: "flex" } }} />
                                                        </Tooltip>
                                                    </Stack.Item>
                                                </Stack>
                                            </Stack.Item>
                                            <SpinButton {...field}
                                                defaultValue='336'
                                                value={field.value !== undefined && String(field.value) !== '' ? String(field.value) : '336'}
                                                title={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.UPTIME_WARNING_LIMIT_NAME}
                                                placeholder={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.UPTIME_WARNING_LIMIT_PLACEHOLDER}
                                                min={1}
                                                max={8766}
                                                step={1}
                                                onChange={(e, v) => {
                                                    field.onChange(Number(v))
                                                }}
                                            />
                                        </Stack>
                                    }
                                />
                            </StackItem>
                        )}
                        {caseElement?.id === DiagnosticCases.TC_SLEEPTIME && (
                            <StackItem>
                                <Controller control={control}
                                    name="sleep_time_percentage_limit"
                                    render={({ field }) =>
                                        <Stack tokens={{ childrenGap: 6 }}>
                                            <Stack.Item>
                                                <Stack horizontal tokens={{ childrenGap: 6 }}>
                                                    <Stack.Item>
                                                        <Text style={{ color: theme.palette.black, fontWeight: 500 }}>{strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.SLEEPTIME_PERCENTAGE_LIMIT_LABEL} (%)</Text>
                                                    </Stack.Item>
                                                    <Stack.Item align='end'>
                                                        <Tooltip content={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.SLEEPTIME_PERCENTAGE_LIMIT_LABEL}>
                                                            <Icon iconName="Info" styles={{ root: { display: "flex" } }} />
                                                        </Tooltip>
                                                    </Stack.Item>
                                                </Stack>
                                            </Stack.Item>
                                            <SpinButton {...field}
                                                defaultValue='20'
                                                value={field.value !== undefined && String(field.value) !== '' ? String(field.value) : '20'}
                                                title={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.SLEEPTIME_PERCENTAGE_LIMIT_LABEL}
                                                placeholder={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.SLEEPTIME_PERCENTAGE_LIMIT_PLACEHOLDER}
                                                min={1}
                                                max={99}
                                                step={1}
                                                onChange={(e, v) => {
                                                    field.onChange(Number(v))
                                                }}
                                            />
                                        </Stack>
                                    }
                                />
                                <Controller control={control}
                                    name="sleep_time_warning_uptime_limit"
                                    render={({ field }) =>
                                        <Stack tokens={{ childrenGap: 6 }}>
                                            <Stack.Item>
                                                <Stack horizontal tokens={{ childrenGap: 6 }}>
                                                    <Stack.Item>
                                                        <Text style={{ color: theme.palette.black, fontWeight: 500 }}>{strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.SLEEPTIME_WARNING_UPTIME_LIMIT_LABEL}</Text>
                                                    </Stack.Item>
                                                    <Stack.Item align='end'>
                                                        <Tooltip content={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.SLEEPTIME_WARNING_UPTIME_LIMIT_LABEL}>
                                                            <Icon iconName="Info" styles={{ root: { display: "flex" } }} />
                                                        </Tooltip>
                                                    </Stack.Item>
                                                </Stack>
                                            </Stack.Item>
                                            <SpinButton {...field}
                                                defaultValue='24'
                                                value={field.value !== undefined && String(field.value) !== '' ? String(field.value) : '24'}
                                                title={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.SLEEPTIME_WARNING_UPTIME_LIMIT_LABEL}
                                                placeholder={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.SLEEPTIME_WARNING_UPTIME_LIMIT_PLACEHOLDER}
                                                min={1}
                                                max={8766}
                                                step={1}
                                                onChange={(e, v) => {
                                                    field.onChange(Number(v))
                                                }}
                                            />
                                        </Stack>
                                    }
                                />
                            </StackItem>
                        )}
                        {caseElement?.id === DiagnosticCases.TC_BATTERY_STRESS && (
                            <Stack.Item>
                                <Stack tokens={{ childrenGap: 10 }} >
                                    <Controller control={control}
                                        name="level_drop"
                                        render={({ field }) =>
                                            <Stack tokens={{ childrenGap: 8 }}>
                                                <Stack horizontal tokens={{ childrenGap: 6 }}>
                                                    <Stack.Item>
                                                        <Text style={{ color: theme.palette.black, fontWeight: 500 }}>{strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_BATTERY_STRESS.LEVEL_DROP}</Text>
                                                    </Stack.Item>
                                                    <Stack.Item align='end'>
                                                        <TooltipHost directionalHint={DirectionalHint.bottomCenter}
                                                            content={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_BATTERY_STRESS.LEVEL_DROP_INFO}
                                                            styles={{ root: { display: "flex", cursor: "default" } }}
                                                            tooltipProps={{
                                                                calloutProps: {
                                                                    styles: {
                                                                        beak: { background: theme.palette.black },
                                                                        beakCurtain: { background: theme.palette.black, color: "red !important", fontWeight: 600 },
                                                                        calloutMain: { background: theme.palette.black, "p": { color: theme.palette.white } }
                                                                    },
                                                                },
                                                            }}>
                                                            <Icon iconName="Info" styles={{ root: { display: "flex" } }} /></TooltipHost>
                                                    </Stack.Item>
                                                </Stack>
                                                <SpinButton {...field}
                                                    id={"level_drop"}
                                                    value={field.value?.toString()}
                                                    onChange={(ev, opt) => field.onChange(Number(opt))}
                                                    min={2}
                                                    max={100}
                                                    step={1}
                                                    incrementButtonAriaLabel="Increase value by 1"
                                                    decrementButtonAriaLabel="Decrease value by 1"
                                                /></Stack>
                                        } />
                                    <Controller control={control}
                                        name="min_battery_level"
                                        render={({ field }) =>
                                            <Stack tokens={{ childrenGap: 8 }}>
                                                <Stack horizontal tokens={{ childrenGap: 6 }}>
                                                    <Stack.Item>
                                                        <Text style={{ color: theme.palette.black, fontWeight: 500 }}>{strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_BATTERY_STRESS.MIN_BATTERY_LEVEL}</Text>
                                                    </Stack.Item>
                                                    <Stack.Item align='end'>
                                                        <TooltipHost directionalHint={DirectionalHint.bottomCenter}
                                                            content={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_BATTERY_STRESS.MIN_BATTERY_LEVEL_INFO}
                                                            styles={{ root: { display: "flex", cursor: "default" } }}
                                                            tooltipProps={{
                                                                calloutProps: {
                                                                    styles: {
                                                                        beak: { background: theme.palette.black },
                                                                        beakCurtain: { background: theme.palette.black, color: "red !important", fontWeight: 600 },
                                                                        calloutMain: { background: theme.palette.black, "p": { color: theme.palette.white } }
                                                                    },
                                                                },
                                                            }}>
                                                            <Icon iconName="Info" styles={{ root: { display: "flex" } }} /></TooltipHost>
                                                    </Stack.Item>
                                                </Stack >
                                                <SpinButton
                                                    id={"min_battery_level"}
                                                    value={field.value?.toString()}
                                                    min={16}
                                                    max={100}
                                                    step={1}
                                                    onChange={(ev, opt) => field.onChange(Number(opt))}
                                                    incrementButtonAriaLabel="Increase value by 1"
                                                    decrementButtonAriaLabel="Decrease value by 1"
                                                /></Stack>} />
                                    {/* <Controller control={control}
                                        name="timeout"
                                        render={({ field }) =>
                                            <Stack tokens={{ childrenGap: 8 }}>
                                                <Stack horizontal tokens={{ childrenGap: 6 }}>
                                                    <Stack.Item>
                                                        <Text style={{ color: theme.palette.black, fontWeight: 500 }}>{strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_BATTERY_STRESS.TIMEOUT}</Text>
                                                    </Stack.Item>
                                                    <Stack.Item align='end'>
                                                        <TooltipHost directionalHint={DirectionalHint.bottomCenter}
                                                            content={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_BATTERY_STRESS.TIMEOUT_INFO}
                                                            styles={{ root: { display: "flex", cursor: "default" } }}
                                                            tooltipProps={{
                                                                calloutProps: {
                                                                    styles: {
                                                                        beak: { background: theme.palette.black },
                                                                        beakCurtain: { background: theme.palette.black, color: "red !important", fontWeight: 600 },
                                                                        calloutMain: { background: theme.palette.black, "p": { color: theme.palette.white } }
                                                                    },
                                                                },
                                                            }}>
                                                            <Icon iconName="Info" styles={{ root: { display: "flex" } }} /></TooltipHost>
                                                    </Stack.Item>
                                                </Stack>
                                                <SpinButton
                                                    labelPosition={Position.top}
                                                    id={"timeout"}
                                                    value={field.value}
                                                    min={0}
                                                    max={100}
                                                    step={1}
                                                    onChange={(ev, opt) => field.onChange(opt)
                                                    }
                                                    incrementButtonAriaLabel="Increase value by 1"
                                                    decrementButtonAriaLabel="Decrease value by 1"
                                                /></Stack>} /> */}
                                    {/* <Controller control={control}
                                        name="data_save_interval"
                                        render={({ field }) =>
                                            <Stack tokens={{ childrenGap: 6 }}>
                                                <Stack horizontal tokens={{ childrenGap: 6 }}>
                                                    <Stack.Item>
                                                        <Text style={{ color: theme.palette.black, fontWeight: 500 }}>{strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_BATTERY_STRESS.DATA_SAVE_INTERVAL}</Text>
                                                    </Stack.Item>
                                                    <Stack.Item align='end'>
                                                        <TooltipHost directionalHint={DirectionalHint.bottomCenter}
                                                            content={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_BATTERY_STRESS.DATA_SAVE_INTERVAL_INFO}
                                                            styles={{ root: { display: "flex", cursor: "default" } }}
                                                            tooltipProps={{
                                                                calloutProps: {
                                                                    styles: {
                                                                        beak: { background: theme.palette.black },
                                                                        beakCurtain: { background: theme.palette.black, color: "red !important", fontWeight: 600 },
                                                                        calloutMain: { background: theme.palette.black, "p": { color: theme.palette.white } }
                                                                    },
                                                                },
                                                            }}>
                                                            <Icon iconName="Info" styles={{ root: { display: "flex" } }} /></TooltipHost>
                                                    </Stack.Item>
                                                </Stack>
                                                <SpinButton
                                                    id={"data_save_interval"}
                                                    value={field.value}
                                                    min={0}
                                                    max={100}
                                                    step={1}
                                                    onChange={(ev, opt) => field.onChange(opt)
                                                    }
                                                    incrementButtonAriaLabel="Increase value by 1"
                                                    decrementButtonAriaLabel="Decrease value by 1"
                                                /></Stack>} /> */}
                                    {/* <Controller control={control}
                                        name="allow_charger"
                                        render={({ field }) =>
                                            <Stack tokens={{ childrenGap: 6 }}>
                                                <Stack.Item>
                                                    <Stack horizontal tokens={{ childrenGap: 6 }}>
                                                        <Stack.Item>
                                                            <Text style={{ color: theme.palette.black, fontWeight: 500 }}>{strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_BATTERY_STRESS.ALLOW_CHARGER}</Text>
                                                        </Stack.Item>
                                                        <Stack.Item align='end'>
                                                            <TooltipHost directionalHint={DirectionalHint.bottomCenter}
                                                                content={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_BATTERY_STRESS.ALLOW_CHARGER_INFO}
                                                                styles={{ root: { display: "flex", cursor: "default" } }}
                                                                tooltipProps={{
                                                                    calloutProps: {
                                                                        styles: {
                                                                            beak: { background: theme.palette.black },
                                                                            beakCurtain: { background: theme.palette.black, color: "red !important", fontWeight: 600 },
                                                                            calloutMain: { background: theme.palette.black, "p": { color: theme.palette.white } }
                                                                        },
                                                                    },
                                                                }}>
                                                                <Icon iconName="Info" styles={{ root: { display: "flex" } }} /></TooltipHost>
                                                        </Stack.Item>
                                                    </Stack>
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <Toggle
                                                        id={"allow_charger"}
                                                        inlineLabel
                                                        checked={field.value}
                                                        onChange={(ev, checked) => field.onChange(checked)}
                                                        onText={strings.BUTTONS.TOGGLE_ENABLE}
                                                        offText={strings.BUTTONS.TOGGLE_DISABLE} />
                                                </Stack.Item>
                                            </Stack>} /> */}
                                    {/* <Controller control={control}
                                        name="show_battery_graph"
                                        render={({ field }) =>
                                            <Stack tokens={{ childrenGap: 6 }}>
                                                <Stack.Item>
                                                    <Stack horizontal tokens={{ childrenGap: 6 }}>
                                                        <Stack.Item>
                                                            <Text style={{ color: theme.palette.black, fontWeight: 500 }}>{strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_BATTERY_STRESS.SHOW_BATTERY_GRAPH}</Text>
                                                        </Stack.Item>
                                                        <Stack.Item align='end'>
                                                            <TooltipHost directionalHint={DirectionalHint.bottomCenter}
                                                                content={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_BATTERY_STRESS.SHOW_BATTERY_GRAPH_INFO}
                                                                styles={{ root: { display: "flex", cursor: "default" } }}
                                                                tooltipProps={{
                                                                    calloutProps: {
                                                                        styles: {
                                                                            beak: { background: theme.palette.black },
                                                                            beakCurtain: { background: theme.palette.black, color: "red !important", fontWeight: 600 },
                                                                            calloutMain: { background: theme.palette.black, "p": { color: theme.palette.white } }
                                                                        },
                                                                    },
                                                                }}>
                                                                <Icon iconName="Info" styles={{ root: { display: "flex" } }} /></TooltipHost>
                                                        </Stack.Item>
                                                    </Stack>
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <Toggle
                                                        id={"show_battery_graph"}
                                                        inlineLabel
                                                        checked={field.value}
                                                        onChange={(ev, checked) => field.onChange(checked)}
                                                        onText={strings.BUTTONS.TOGGLE_ENABLE}
                                                        offText={strings.BUTTONS.TOGGLE_DISABLE} />
                                                </Stack.Item>
                                            </Stack>} /> */}
                                    
                                    {/* <Controller control={control}
                                        name="cpu_load"
                                        render={({ field }) =>
                                            <Dropdown {...field}
                                                id={"cpu_load"}
                                                placeholder={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_BATTERY_STRESS.CPU_LOAD_PLACEHOLDER}
                                                options={cpuLoadOptionsBatteryTest}
                                                onRenderLabel={onRenderLabel}
                                                title={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_BATTERY_STRESS.CPU_LOAD_INFO}
                                                label={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_BATTERY_STRESS.CPU_LOAD}
                                                selectedKey={field.value}
                                                onChange={(ev, opt) => field.onChange(opt?.key as string)}
                                            />} /> */}
                                    {/* <Controller control={control}
                                        name="skipanalysis"
                                        render={({ field }) =>
                                            <Stack tokens={{ childrenGap: 6 }}>
                                                <Stack.Item>
                                                    <Stack horizontal tokens={{ childrenGap: 6 }}>
                                                        <Stack.Item>
                                                            <Text style={{ color: theme.palette.black, fontWeight: 500 }}>{strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_BATTERY_STRESS.SKIPANALYSIS}</Text>
                                                        </Stack.Item>
                                                        <Stack.Item align='end'>
                                                            <TooltipHost directionalHint={DirectionalHint.bottomCenter}
                                                                content={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_BATTERY_STRESS.SKIPANALYSIS_INFO}
                                                                styles={{ root: { display: "flex", cursor: "default" } }}
                                                                tooltipProps={{
                                                                    calloutProps: {
                                                                        styles: {
                                                                            beak: { background: theme.palette.black },
                                                                            beakCurtain: { background: theme.palette.black, color: "red !important", fontWeight: 600 },
                                                                            calloutMain: { background: theme.palette.black, "p": { color: theme.palette.white } }
                                                                        },
                                                                    },
                                                                }}>
                                                                <Icon iconName="Info" styles={{ root: { display: "flex" } }} /></TooltipHost>
                                                        </Stack.Item>
                                                    </Stack>
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <Toggle
                                                        id={"skipanalysis"}
                                                        inlineLabel
                                                        checked={field.value}
                                                        onChange={(ev, checked) => field.onChange(checked)}
                                                        onText={strings.BUTTONS.TOGGLE_ENABLE}
                                                        offText={strings.BUTTONS.TOGGLE_DISABLE} />
                                                </Stack.Item>
                                            </Stack>} /> */}
                                </Stack>
                            </Stack.Item>)}
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8, padding: '20px 0' }}>
                        <PrimaryButton onClick={handleSubmit(onSubmit)} disabled={errorMessageText ? true : false} text={strings.BUTTONS.TEXT.CONFIRM} />
                        <DefaultButton text={strings.BUTTONS.TEXT.CANCEL} onClick={() => handleCancelClick()} />
                    </Stack>
                </Stack.Item>
            </Stack>
        </form>
    )
}

const theme = getTheme();